@import '../../assets/scss/variables.scss';
@import '../../assets/scss/placeholders.scss';
@import '../../assets/scss/mixins.scss';

%tabsItemBorder {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  width: 1px;
  height: 22px;
  z-index: 1;
}
.tabs {
  display: flex;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  &-item {
    position: relative;
    width: 94px;
    height: 42px;
    padding: 12px 0;
    border: 4px solid $white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    color: $dark-bg;
    text-align: center;
    cursor: pointer;
    &:not(:last-child)::after {
      @extend %tabsItemBorder;
      right: -5px;
      opacity: 0.1;
      background-color: $main-color-text;
    }
    &:hover {
      @extend %whiteBtnHover;
      &::after {
        visibility: hidden;
      }
      &:not(:first-child)::before {
        @extend %tabsItemBorder;
        left: -4px;

        opacity: 1;
        background-color: $white;
      }
    }
    &-selected {
      @extend .tabs-item;
      background-color: $dark-bg;
      color: $white;
      transition: background-color 0.3s ease-in-out;
      z-index: 1;
      &:hover {
        background-color: $dark-bg;
      }
      &::after {
        visibility: hidden;
      }
      &:not(:first-child)::before {
        @extend %tabsItemBorder;
        left: -4px;
        opacity: 1;
        background-color: $white;
      }
    }
  }
}
