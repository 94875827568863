@import '../../assets/scss/mixins';

.legend {
  z-index: 1;
  display: flex;
  margin: 0 auto;
  border-radius: 5px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  list-style: none;
  li {
    display: inline;
    font-size: 14px;
    margin: 6px;
    height: 100%;
  }
  li label {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 25px;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    //new gray disabled legend style
    background-color: #c5c5c5;
    border: 2px solid #c5c5c5;
    color: #fff;
  }

  //old disabled legend label style

  // li .settings {
  //   border: 2px solid #786cba;
  //   color: #786cba;
  // }

  // li .process {
  //   border: 2px solid #ce5685;
  //   color: #ce5685;
  // }

  // li .subject {
  //   border: 2px solid #ff8c40;
  //   color: #ff8c40;
  // }

  // li .resource {
  //   border: 2px solid #0084ad;
  //   color: #0084ad;
  // }

  li input[type='checkbox']:checked + .settings {
    border: 2px solid #786cba;
    background-color: #786cba;
    color: #fff;
    &:hover {
      @include colorBtnHover(#786cba);
    }
  }

  li input[type='checkbox']:checked + .process {
    border: 2px solid #ce5685;
    background-color: #ce5685;
    color: #fff;
    &:hover {
      @include colorBtnHover(#ce5685);
    }
  }

  li input[type='checkbox']:checked + .subject {
    border: 2px solid #ff8c40;
    background-color: #ff8c40;
    color: #fff;
    &:hover {
      @include colorBtnHover(#ff8c40);
    }
  }

  li input[type='checkbox']:checked + .resource {
    border: 2px solid #0084ad;
    background-color: #0084ad;
    color: #fff;
    &:hover {
      @include colorBtnHover(#0084ad);
    }
  }

  li input[type='checkbox'] {
    display: none;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-weight: bold;
    opacity: 1;
    cursor: pointer;
    border-radius: 10px;
    &:last-child {
      margin-right: 0;
    }
    &_setting {
      width: 1rem;
      height: 1rem;
      background-color: white;
      margin-right: 5px;
      -webkit-mask: url('../../assets/img/icons/graph/setting.svg') no-repeat;
      mask: url('../../assets/img/icons/graph/setting.svg') no-repeat;
      // old disabled legend
      // &_icon {
      //   background-color: #786cba;
      // }
    }
    &_process {
      width: 1rem;
      height: 1rem;
      background-color: white;
      margin-right: 5px;
      -webkit-mask: url('../../assets/img/icons/graph/process.svg') no-repeat;
      mask: url('../../assets/img/icons/graph/process.svg') no-repeat;
      // old disabled legend
      // &_icon {
      //   background-color: #ce5685;
      // }
    }
    &_resource {
      width: 1rem;
      height: 1rem;
      background-color: white;
      margin-right: 5px;
      -webkit-mask: url('../../assets/img/icons/graph/asset.svg') no-repeat;
      mask: url('../../assets/img/icons/graph/asset.svg') no-repeat;
      // old disabled legend
      // &_icon {
      //   background-color: #0084ad;
      // }
    }
    &_subject {
      width: 1rem;
      height: 1rem;
      background-color: white;
      margin-right: 5px;
      -webkit-mask: url('../../assets/img/icons/graph/subject.svg') no-repeat;
      mask: url('../../assets/img/icons/graph/subject.svg') no-repeat;
      // old disabled legend
      // &_icon {
      //   background-color: #ff8c40;
      // }
    }
  }
}
