@import '../../assets/scss/placeholders.scss';

.pagination {
  display: flex;
  align-items: center;
  margin-left: auto;
  min-width: 50px;
  height: 42px;
  border-radius: 20px;
  &-text {
    font-size: 14px;
    color: #333333;
  }
}
