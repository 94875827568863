@import '../../../assets/scss/variables.scss';

.user {
  &-container {
    width: 584px;
    height: 96vh;
    margin: 2vh auto auto auto;
    &-block {
      padding: 2rem;
      width: inherit;
      align-items: center;
      flex-wrap: wrap;
      background: $white;
      margin-bottom: 1rem;
      border-radius: 20px;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.password-change {
  &__title {
    font-size: 20px;
    cursor: default;
  }

  &__times {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 20px;
    height: 20px;
    background-color: #333;
    cursor: pointer;
    -webkit-mask: url('../../../assets/img/icons/feeds/roundTimes.svg')
    no-repeat;
    mask: url('../../../assets/img/icons/feeds/roundTimes.svg') no-repeat;
  }

  &__form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  &__footer {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;

    .footer__button {
      width: 80px;
      outline: none;
      border-radius: 21px;
      border: 0;
      font-family: $main-font;
      padding: 13px 0;
      font-weight: bold;
      cursor: pointer;

      &_cancel {
        background-color: #f0f0f0;
        color: $dark-bg;
        &:hover {
          background-color: rgba($main-color-text, 0.1);
        }
      }

      &_submit {
        margin-left: 10px;
        background-color: $dark-bg;
        color: $white;
        &:hover:not(:disabled) {
          background-color: rgba($dark-bg, 0.9);
        }
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}