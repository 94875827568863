.pr-2 {
  padding-right: 2px;
}

.pr-4 {
  padding-right: 4px;
}

.pr-6 {
  padding-right: 6px;
}

.pr-8 {
  padding-right: 8px;
}
.pr-10 {
  padding-right: 10px;
}

.pt-2 {
  padding-top: 2px;
}

.pt-4 {
  padding-top: 4px;
}

.pt-6 {
  padding-top: 6px;
}

.pt-8 {
  padding-top: 8px;
}
.pt-10 {
  padding-top: 10px;
}

.pl-2 {
  padding-left: 2px;
}

.pl-4 {
  padding-left: 4px;
}

.pl-6 {
  padding-left: 6px;
}

.pl-8 {
  padding-left: 8px;
}
.pl-10 {
  padding-left: 10px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pb-8 {
  padding-bottom: 8px;
}
.pb-10 {
  padding-bottom: 10px;
}
.mr-0 {
  margin-right: 0px;
}
.mr-2 {
  margin-right: 2px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}

.ml-22 {
  margin-left: 22px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}
.ml-2 {
  margin-left: 2px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}
.ml-10 {
  margin-left: 10px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-30 {
  margin-bottom: 30px;
}
