@import "../../assets/scss/variables.scss";

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba($white, 0.6);
  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // border: 4px solid $white-color;
    // border-color: $white-color transparent $white-color transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    border: 4px solid $mint;
    border-color: $mint transparent $mint transparent;
  }
}