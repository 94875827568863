.notifications-box {
  position: absolute;
  width: 300px;
  left: 20px;
  bottom: 20px;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}