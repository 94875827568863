@import '../../assets/scss/variables.scss';

.filter-panel {
  width: 100%;
  height: 100%;
  padding: 10px 40px 11px 10px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background-color: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 12px 0 rgba(51, 50, 50, 0.1);
  border-radius: 21px;
  cursor: pointer;

  &__title {
    font-size: 14px;
    font-family: $main-font;
    white-space: nowrap;
  }

  &__select {
    width: 100%;
    padding: 15px 0;
    position: absolute;
    top: 45px;
    right: 0;

    text-align: left;
    background-color: white;
    border-radius: 21px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 4px 12px 0 rgba(51, 50, 50, 0.1);
    z-index: 10;

    &-item {
      padding: 10px 20px;

      font-size: 14px;
      font-family: $main-font;
      transition: $transition;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      &_active {
        background-color: #ebebeb;
        &:hover {
          background-color: #ebebeb;
        }
      }
    }
  }

  &__arrow {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}