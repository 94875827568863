@import '../../assets/scss/variables.scss';

.modal {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.details {
  &__container {
    width: 520px;
    max-height: 96vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 30px 0px 30px 30px;
    margin-bottom: 1rem;
    border-radius: 20px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    padding-right: 4px;

    &-modal {
      @extend .details__container;
      background: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
