@import '../../assets/scss/variables.scss';

.footer {
  position: absolute;
  font-size: 14px;
  color: $white;
  bottom: 1rem;
  text-align: center;
  width: 100%;
  text-shadow: 0 0 10px #00000069;

  &_opacity {
    opacity: 0.5;
  }

  &_onTop {
    z-index: 1000;
  }

  .copyright {
    margin: 0 auto;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}