@import '../../../assets/scss/variables';

%button {
  padding: 13px 26px;
  min-width: 96px;

  font-family: $main-font;
  outline: none;
  border: none;
  border-radius: $border-radius;
  font-size: 14px;
  font-weight: bold;
  transition: $transition;
  cursor: pointer;
}

.help-modal {
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;
    padding: 40px 30px 30px;

    font-family: $main-font;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }

  &__header {
    padding-right: 30px;
  }

  &__title {
    font-size: 20px;
  }

  &__times {
    position: absolute;
    right: 30px;
    top: 20px;
    width: 20px;
    height: 20px;
    background-color: #333;
    cursor: pointer;
    -webkit-mask: url('../../../assets/img/icons/feeds/roundTimes.svg') no-repeat;
    mask: url('../../../assets/img/icons/feeds/roundTimes.svg') no-repeat;
  }

  &__body {
    margin-top: 20px;
    line-height: 1.5;
  }

  &__text {
    &_important {
      font-weight: bold;
    }
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__accept-btn {
    @extend %button;
    background-color: $dark-bg;
    color: $white;
    &:hover {
      background: rgba($dark-bg, 0.9);
    }
  }
}