@import '../../assets/scss/variables.scss';

.list__item {
  .checkbox-container {
    display: block;
    position: relative;
    line-height: 19px;
    padding-left: 22px;
    cursor: default;
    font-size: 14px;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: default;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    border: solid gray 2px;
    background-color: #fff;
  }

  /* On mouse-over, add a darken blue border color */
  .checkbox-container:hover input[type='checkbox'] ~ .checkmark {
    border-color: $dark-bg;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input[type='checkbox']:checked ~ .checkmark {
    background-color: $dark-bg;
    border: 0px;
  }

  /* On mouse-over, when the checkbox is checked, add a darken blue background color */
  .checkbox-container:hover input[type='checkbox']:checked ~ .checkmark,
  .checkbox-container:hover input[type='checkbox']:checked ~ .checkmark-some {
    background-color: #02717b;
    border: 0px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input[type='checkbox']:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 5.4px;
    top: 1px;
    width: 3px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
