@import '../../assets/scss/variables.scss';
@import '../../assets/scss/placeholders.scss';
@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/indent.scss';

.filter-bar {
  top: 5rem;
  left: 1.2rem;
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(96vh - 85px);
  width: 0px;
  border: 0px;
  background: #ffffff00;
  color: #000;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem 0 1rem;
    border-radius: 24px;
  }
  &__button {
    position: fixed;
    width: 42px;
    height: 42px;
    background-color: $white;
    border-radius: 50%;
    top: 80px;
    left: 19px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:hover {
      @extend %whiteBtnHover;
    }
    &-icon {
      width: inherit;
      height: inherit;
      background-color: $dark-bg;
      -webkit-mask: url('../../assets/img/icons/filters/filter.svg') no-repeat
        50% 50%;
      mask: url('../../assets/img/icons/filters/filter.svg') no-repeat 50% 50%;
    }
  }
  &__number {
    top: 0px;
    left: 23px;
    position: absolute;
    text-align: center;
    line-height: 17px;
    width: 18px;
    height: 18px;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: #dd0000;
    border-radius: 50%;
    color: #fff;
    z-index: 2;
  }
  &__role {
    display: none;
    width: 15rem;
    padding: 0 0.5rem;
    .css-26l3qy-menu {
      position: absolute;
      top: 0px;
      left: -1px;
      width: 100.5%;
    }
  }
  &__select {
    width: 100%;
    font-family: Roboto;
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
      color: rgba(0, 0, 0, 0.8);
      &:hover {
        color: $black;
      }
    }
  }
  &__title {
    display: none;
  }
  &__main {
    display: none;
    flex: 1;
    background: #fff;
    overflow-y: auto;
    padding: 1rem;
    margin: 0rem 0.25rem 0.45rem 0rem;
    border-radius: 20px;
    color: #333;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d6d6d6;
    }
    .filters {
      position: relative;
      font-size: 14px;
      border-radius: 10px;
      margin-bottom: 1rem;
      padding: 0rem 0.5rem;
      &-note {
        @extend .filters;
        opacity: 0.5;

        .filters-list {
          margin-left: 0;
        }
      }
      &__title {
        color: $main-color-text;
        cursor: pointer;
      }
      &-list {
        margin-left: 25px;
      }
      .list__item {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
        &__checkbox {
          margin: 0;
          margin-right: 0.5rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__expand {
        cursor: pointer;
        margin-right: 8px;
      }
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
      &__lang {
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        margin-bottom: 0.5rem;
        .css-26l3qy-menu {
          position: absolute;
          top: 0px;
          left: -1px;
          width: 100.5%;
        }
      }
      &__help {
        margin-left: 0.5rem;
        padding-right: 10px;
        height: 20px;
        &-trigger {
          width: 20px;
          height: 20px;
          display: inline-block;
          cursor: pointer;
          background: url('../../assets/img/icons/filters/information.svg') 0 0/100%
            100% no-repeat;
          opacity: 0.2;
          -o-transition: opacity 0.2s;
          transition: opacity 0.2s;
          z-index: 5;
        }
        &-trigger:hover,
        &-opened .filters__help-trigger {
          opacity: 0.7;
        }
        &-inner {
          max-height: 350px;
          padding: 25px 40px;
          box-sizing: border-box;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          backface-visibility: hidden;
        }

        &-opened .popover {
          visibility: visible;
          opacity: 1;
          -o-transform: translateX(0);
          transform: translateX(0);
        }
      }
      &__count {
        margin-left: auto;
        color: #dd0000;
      }
      &__radio {
        @extend .filters;

        &-header {
          @extend .filters__header;
          opacity: 0.5;
        }

        &-group {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .radio-container {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            padding-left: 20px;
            margin-right: 20px;
            cursor: pointer;
            /* Hide the browser's default radio button */
            & input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
            }
          }

          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: white;
            border: solid gray 2px;
            border-radius: 50%;
          }

          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* On mouse-over, add a grey background color */
          .radio-container:hover input ~ .checkmark {
            //background-color: #ccc;
            border-color: $dark-bg;
          }

          /* When the radio button is checked, add a blue background */
          .radio-container input:checked ~ .checkmark {
            // background-color: white;
            border: solid #00838f 2px;
          }

          /* Show the indicator (dot/circle) when checked */
          .radio-container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the indicator (dot/circle) */
          .radio-container .checkmark:after {
            top: 2px;
            left: 2px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #00838f;
          }
        }
      }
    }
    .filter-toggle {
      width: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10px;
      margin-bottom: 16px;
      margin-right: auto;
      cursor: pointer;

      &__label {
        margin-right: 10px;
        font-family: $main-font;
        font-size: 14px;
        opacity: 0.5;
      }

      &__track {
        position: relative;
        height: 14px;
        width: 35px;

        border-radius: $border-radius;
        background-color: rgba($main-color-text, 0.5);

        &_checked {
          @extend .filter-toggle__track;
          background-color: rgba($dark-bg, 0.5);
        }
      }

      &__thumb {
        position: absolute;
        height: 20px;
        width: 20px;
        top: -3px;
        left: -1px;

        border-radius: $border-radius;
        box-shadow: $box-shadow;
        background-color: $light-bg;
        transition: left 2s, right 2s;

        &_checked {
          @extend .filter-toggle__thumb;
          left: auto;
          right: -1px;
          background-color: $dark-bg;
        }
      }
    }
  }

  &__clear-button {
    display: none;
    min-width: 50px;
    padding: 0.5rem 0.5rem;
    border: 0px;
    outline: none;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    margin-left: auto;
  }
  &__reset {
    background: transparent;
    color: $dark-bg;
    &:disabled {
      opacity: 0.5;
    }
  }
}

.popover {
  width: 600px;
  position: absolute;
  top: -26px;
  left: 100%;
  line-height: 19px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.05);
  z-index: 200;
  visibility: hidden;
  opacity: 0;
  -o-transform: translateX(80px);
  transform: translateX(80px);
  -o-transition: all 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
  transition: all 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
}

.filter-bar-active {
  width: 320px;
  height: calc(96vh - 85px);
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
.filter-bar-active .filter-bar__clear-button,
.filter-bar-active .filter-bar__title,
.filter-bar-active .filter-bar__main {
  display: block;
}

.filter-bar-active .filter-bar__button {
  background-color: $dark-bg;
  &:hover {
    @include colorBtnHover($dark-bg);
  }
  &-icon {
    background-color: white;
    -webkit-mask: url('../../assets/img/icons/filters/filter.svg') no-repeat 50%
      50%;
    mask: url('../../assets/img/icons/filters/filter.svg') no-repeat 50% 50%;
  }
}

.filter-bar-active .filter-bar__role {
  display: flex;
}
