@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

// Fonts
$main-font: 'Roboto', sans-serif;

// Colors
$white: #fff;
$black: #000;
$mint: #31b68a;
$grey: #bfc2c2;
$light-bg: #f0f0f0;
$dark-bg: #00838f;
$scroll-color-thumb: #7692a5;
$main-color-text: #333;
$red: red;
$gray: gray;
$orange: orange;
$brown: brown;
$errorText: #f44336;
$loginErrorText: #ff8c40;
$loginHelpText: #fff;

$transition: 0.2s;
$animation: 0.5s;
$box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
$border-radius: 21px;
